import Axios from "axios";
import token from "./setToken";

export default {
  async search(searchCondition) {
  
    let params = new URLSearchParams();
    params.append('searchCondition', JSON.stringify(searchCondition));

    try {
      const res = await Axios.post('/api/MailSendHistory', params, token.setTokenToHeader());
      return res;

    } catch (error) {
      console.error("メール送信履歴を取得できません");
      throw error;
    }
  },
};