<template>
  <v-container>
    <v-row >
      <v-col cols="2">
        <h2>メール送信履歴</h2>
      </v-col>
    </v-row>

    <v-row >
      <v-col cols="4">
        <div class="flex">
          <DatePicker
            label="検索開始"
            v-model="searchCondition.fromDate"
            format="YYYY/MM/DD"
            selectType="date"
            :loading="loading"
          ></DatePicker>
          <div class="mx-5" >～</div>
          <DatePicker
            label="検索終了"
            v-model="searchCondition.toDate"
            format="YYYY/MM/DD"
            selectType="date"
            :loading="loading"
          ></DatePicker>
        </div>
      </v-col>
      <v-col cols="2">
          <v-btn class="btn_font primary " width="100" v-on:click="loadList()" :disabled="loading"
        >検索</v-btn>
      </v-col>
      <v-col cols="4">
        <v-text-field label="検索" 
            dense 
            v-model="searchCondition.searchString" 
            :disabled="loading"
            :loading="loading"
        ></v-text-field>

      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :footers="headers"
      :items="items"
      :loading="loading"
      dense
      :mobile-breakpoint="0"
      :disable-pagination='true'
      :hide-default-footer='true'
      :fixed-header='true'
      height="calc(100vh - 250px)"
      multi-sort
      locale="ja-jp"
      loading-text="読込中"
      no-data-text="データがありません。"
      :search="searchCondition.searchString"
      class="mt-5"
    >
      <template v-slot:item="{ item, index }">
        <tr :key="index" >
          <td>
            {{item.insert}}
          </td>
          <td>
            {{item.bcc}}
          </td>
          <td>
            {{item.to}}
          </td>
          <td>
            {{item.cc}}
          </td>
          <td>
            {{item.from}}
          </td>
          <td>
            {{item.subject}}
          </td>
          <td>
            {{item.body}}
          </td>
          <td class="preline">
            {{arrayjoin(item.attachement)}}
          </td>

        </tr>
      </template>
    </v-data-table>

  </v-container>

</template>

<script>
import MailSendHistory from '../model/mailSendHistory'
import DatePicker from "./common/DatePicker.vue";

import Util from '../Lib/Util';
import Message from '../Lib/Message';
import Debug from '../Lib/Debug';


export default {
  components: {
    DatePicker,
  },

  data: () => ({
    loading: false,

    headers: [
      { text: '日時', align: 'left', sortable: true, value: 'datetime', width: "100px" },
      { text: 'BCC', align: 'left', sortable: true, value: 'bcc', width: "40vw" },
      { text: 'To', align: 'left', sortable: true, value: 'to', width: "160px" },
      { text: 'Cc', align: 'left', sortable: true, value: 'cc', width: "160px" },
      { text: 'From', align: 'left', sortable: true, value: 'from', width: "200px" },
      { text: 'Subject', align: 'left', sortable: true, value: 'subject', width: "200px" },
      { text: '本文', align: 'left', sortable: false, value: 'body', width: "200px" },
      { text: '添付', align: 'left', sortable: false, value: 'attachement', width: "200px" },
      // { text: '', align: 'center', sortable: false}
    ],

    items: [],

    // 検索条件
    searchCondition: {
      fromDate: '',
      toDate: '',
      searchString: '',
    },

  }),

  methods: {
    async loadList() {
      Debug.log('function[loadList]');

      this.loading = true
      // メール送信履歴を取得
      MailSendHistory.search(this.searchCondition)
        .then(res => {
          if (res && res.data) {
            this.items = res.data;
          }
        })
        .catch((err) => {
          Message.err(err, '情報を取得できませんでした。[メール送信履歴]');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    arrayjoin(attach) {
      return attach.join("\n");
    },

  },

  created: function() {
  },

  mounted : function() {
    // 日付範囲を計算
    const to_day = new Date();
    const from_day = new Date();
    from_day.setMonth(from_day.getMonth() - 1);
    this.searchCondition.fromDate = Util.convDateString(from_day);
    this.searchCondition.toDate = Util.getToday(to_day);

    // 
    this.loadList();
},

}
</script>


<style scoped>
.v-data-table table {
  width: initial !important;
}

.v-data-table table th {
  font-size: 12pt;
}

.v-data-table table td {
  font-size: 11pt;
}

.btn-font {
  color: white;
}

.v-text-field {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}


.flex {
  display: flex;
}

.preline {
  white-space: pre-line;
}


</style>
